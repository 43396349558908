import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Qrcode = () => {
  const [qrUrl, setQrUrl] = useState(null);
  const [loginHash, setLoginHash] = useState(null);
  const [logo, setLogo] = useState(null); // State to store logo URL
  const [error, setError] = useState(null);
  const [sessionMessage, setSessionMessage] = useState(
    "Session is not created yet. Please wait..."
  );
  const [sessionCheckInterval, setSessionCheckInterval] = useState(null);

  const navigate = useNavigate();

  // Generate QR Code on component load
  useEffect(() => {
    generateQrCode();
  }, []);

  // Clean up interval when component unmounts
  useEffect(() => {
    return () => {
      if (sessionCheckInterval) clearInterval(sessionCheckInterval);
    };
  }, [sessionCheckInterval]);

  const generateQrCode = async () => {
    if (!navigator.geolocation) {
      setError("Geolocation is not supported by your browser.");
      return;
    }

    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;

        try {
          const response = await fetch(
            "https://needu.tech/admin/getLoginQrCode",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ latitude, longitude }),
            }
          );

          const result = await response.json();
          if (!result.error && result.errorCode === 200) {
            setQrUrl(result.data.qr);
            setLoginHash(result.data.loginHash);
            setLogo(result.data.logo); // Set the logo URL
            sessionStorage.setItem("logo", result.data.logo); // Save logo in sessionStorage
            startSessionCheck(result.data.loginHash); // Start session check after QR code is loaded
          } else {
            setError(result.message || "Error generating QR code.");
          }
        } catch (err) {
          setError("Failed to connect to the server.");
        }
      },
      () => {
        setError("Please allow location access to generate the QR code.");
      }
    );
  };

  const startSessionCheck = (token) => {
    const interval = setInterval(async () => {
      try {
        const response = await fetch("https://needu.tech/admin/checkSession", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token }),
        });

        const result = await response.json();
        if (!result.error && result.errorCode === 200) {
          clearInterval(interval); // Stop checking
          setSessionCheckInterval(null); // Clear interval state

          // Navigate to UserForm with userData
          navigate("/user-form", { state: { userData: result.data } });
        } else if (result.error) {
          setSessionMessage("Session is not created yet. Please wait...");
        }
      } catch (err) {
        setSessionMessage("Error checking session. Please try again.");
      }
    }, 5000); // Check session every 5 seconds

    setSessionCheckInterval(interval);
  };

  return (
    <div className="Qrdiv">
      {/* Logo */}
      {logo && (
        <div style={{ marginBottom: "20px" }}>
          <img
            src={logo}
            alt="Company Logo"
            style={{ maxHeight: "100px", width: "auto" }}
          />
        </div>
      )}

      <h1>QR Code Login</h1>

      {error && <p style={{ color: "red" }}>{error}</p>}

      {qrUrl ? (
        <div>
          <h3>Scan the QR Code to Login</h3>
          <img
            src={qrUrl}
            alt="QR Code"
            className="qrcodimg"
          />
          {sessionMessage && (
            <p style={{ color: "#ffb000" }}>{sessionMessage}</p>
          )}
        </div>
      ) : (
        !error && <p>Loading QR Code...</p>
      )}
    </div>
  );
};

export default Qrcode;
