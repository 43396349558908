import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import UserForm from "./UserForm";
import Qrcode from "./Qrcode";
import "./App.css";

const App = () => {
  const [logo, setLogo] = useState("https://needu.tech/storage/uploads/images/companylogo/needulogo.png"); // Manage the logo state in App
  return (
    <Router>
       
      <Routes>
        {/* Set Qrcode as the default home page */}
        <Route path="/" element={<Qrcode />} />
        {/* Add other routes like UserForm if needed */}
        <Route path="/user-form" element={<UserForm />} />
      </Routes>
    </Router>
  );
};

export default App;

