import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const UserForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userData } = location.state || {};
  const logo = sessionStorage.getItem("logo"); // Retrieve logo from sessionStorage
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState(null);
  const [uploadedData, setUploadedData] = useState(null);
  const [timer, setTimer] = useState(300); // 5 minutes in seconds
  const [showBackButton, setShowBackButton] = useState(false);

  // Redirect to QRCode page if userData is null
   useEffect(() => {
      if (!userData) {
        navigate("/"); // Navigate to QRCode page
      }
    }, [userData, navigate]);

  let countdown; // Store the interval for clearing

  // Start the countdown timer
  useEffect(() => {
    if (timer === 0) {
      setShowBackButton(true); // Show back button when timer ends
      return;
    }

    countdown = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 1) {
          clearInterval(countdown);
          setShowBackButton(true); 
          navigate("/");
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(countdown); // Cleanup timer on unmount
  }, [timer]);

  // Stop timer when uploading or after success
  const stopTimer = () => {
    if (countdown) {
      clearInterval(countdown);
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      setResponseMessage("Please select a file.");
      return;
    }

    if (
      selectedFile.type !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setResponseMessage("Only Excel files are allowed.");
      return;
    }

    if (selectedFile.size > 5 * 1024 * 1024) {
      setResponseMessage("File size must be less than 5 MB.");
      return;
    }

    setFile(selectedFile);
    setResponseMessage(null); // Clear previous messages
  };

  const handleFileUpload = async () => {
    if (!file) {
      setResponseMessage("Please select a file before uploading.");
      return;
    }

    stopTimer(); // Stop the timer when the upload starts

    const formData = new FormData();
    formData.append("file", file);

    setLoading(true);
    setResponseMessage(null);

    try {
      // Replace with the actual API call
      const response = await fetch("https://needu.tech/admin/upload-file", {
        method: "POST",
        headers: {
          Accept: "application/json",
          // Add Authorization if required:
          Authorization: `Bearer ${userData.bearer}`,
        },
        body: formData,
      });

      const result = await response.json();

      setLoading(false);
      setShowBackButton(true);

      if (!result.error) {
        setUploadedData(result.data);
        setResponseMessage(result.message || "File uploaded successfully!");
      } else {
        setResponseMessage(result.message || "Error uploading file.");
      }
    } catch (err) {
      setLoading(false);
      setResponseMessage("Failed to process the file.");
    }
  };

  const handleBackToQRCode = () => {
    navigate("/");
  };

  return (
    <div className="usercontainer">
      <div className="userformright">
        <h2>Upload Excel For Bulk Transactions</h2>
        <hr></hr>
        <p>
          Please carefully review the following rules and guidelines before
          uploading your Excel file to ensure a smooth and successful
          transaction process.
        </p>
        Rules for Uploading Transactions:
        <ul>
          <li>Each Batch cannot exceed a maximum of 500 transactions.</li>
          <li>
            The apiReferenceID must be unique for every individual transaction.
          </li>
          <li>
            Duplicate account numbers within a single upload are not permitted.
          </li>
          <li>
            The minimum transaction amount is 100, and the maximum allowable
            amount per transaction is 100,000.
          </li>
          <li>
            Only one job can be processed at a time. If a job is pending, no
            additional jobs can be created until the current job is completed.
          </li>
        </ul>
          
        <h4>Click here to Download sample excel file </h4>

         <a href="demo.xlsx" className="excellink" download="SampleExcel.xlsx">
          Download Excel
          <img src="excel.png" alt="logo" className="excelimg"></img>
          </a>

      </div>
      <div className="userformleft">
        {logo && (
          <div style={{ textAlign: "center" }}>
            <img
              src={logo}
              alt="Company Logo"
              style={{ maxHeight: "auto", width: "150px" }}
            />
          </div>
        )}

        {/* Conditionally hide user info if error is false */}
        {!uploadedData && (
          <div>
            <h1>User Information</h1>
            <hr />
            <form>
              <div>
                <label>Name</label>
                <input type="text" value={userData?.name || ""} readOnly />
              </div>
              <div>
                <label>Email</label>
                <input type="text" value={userData?.email || ""} readOnly />
              </div>
              <div>
                <label>Mobile</label>
                <input type="text" value={userData?.mobile || ""} readOnly />
              </div>
            </form>
          </div>
        )}

        {/* Conditionally hide the Upload Excel File section */}
        {!uploadedData && (
          <div style={{ marginTop: "30px" }}>
            <h3>Upload Excel File</h3>
            <input
              type="file"
              accept=".xlsx"
              onChange={handleFileChange}
              disabled={loading}
            />
            <button
              onClick={handleFileUpload}
              disabled={loading}
              className="mybtn"
            >
              Upload
            </button>
          </div>
        )}

        {loading && (
          <div style={{ marginTop: "20px" }}>
            <p>Loading...</p>
            <div className="loader"></div>
          </div>
        )}

        {responseMessage && (
          <p
            style={{ color: uploadedData ? "green" : "red", marginTop: "20px" }}
          >
            {responseMessage}
          </p>
        )}

        {uploadedData && (
          <div style={{ marginTop: "20px" }}>
            <h3>Uploaded File Details</h3>
            <p>
              <strong>Batch ID:</strong> {uploadedData.batch_id}
            </p>
            <p>
              <strong>Total Amount:</strong> {uploadedData.totalAmount}
            </p>
            <p>
              <strong>Total Records:</strong> {uploadedData.totalRecords}
            </p>
            <p>
              <strong>Total Tax:</strong> {uploadedData.totalTax}
            </p>
            <p>
              <strong>Total Charges:</strong> {uploadedData.totalCharges}
            </p>
          </div>
        )}

        {!showBackButton && (
          <p style={{ marginTop: "30px", color: "gray", fontSize: "14px" }}>
            Redirecting to QRCode page in {Math.floor(timer / 60)}:
            {String(timer % 60).padStart(2, "0")} minutes...
          </p>
        )}
        <button
          onClick={handleBackToQRCode}
          style={{
            marginTop: "20px",
            padding: "10px 20px",
            backgroundColor: "#867bff",
            borderRadius:"10px",
            fontWeight:"200",
            color: "#fff",
            border: "none",
            cursor: "pointer",
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default UserForm;
